export const Constants = {
  'title': 'Happy Dyslectisch Toolbox',
  'infoPanel': {
    'width': 440,
    'breakpoint': 760
  },
  'map': {
    'logo-billboard': {
      x: -110,
      y: 35,
      w: 213,
      h: 415
    },
    'hoi': {
      x: 798,
      y: 471,
      w: 185,
      h: 437
    },
    'info-centrum': {
      x: 322,
      y: 336,
      w: 263,
      h: 350,
      count: {
        x: 535,
        y: 449,
      }
    },
    'bibliotheek': {
      x: 465,
      y: 688,
      w: 200,
      h: 242,
      count: {
        x: 675,
        y: 789,
      }
    },
    'thuis': {
      x: -52,
      y: 401,
      w: 234,
      h: 297,
      count: {
        x: 154,
        y: 509,
      }
    },
    'speelplek': {
      x: 603,
      y: 311,
      w: 255,
      h: 395,
      count: {
        x: 868,
        y: 427,
      }
    },
    'basisschool': {
      x: 132,
      y: 99,
      w: 396,
      h: 296,
      count: {
        x: 538,
        y: 222,
      }
    },
    'middelbare-school': {
      x: 582,
      y: 86,
      w: 345,
      h: 265,
      count: {
        x: 938,
        y: 283,
      }
    },
    'sportclub': {
      x: 24,
      y: 550,
      w: 382,
      h: 377,
      count: {
        x: 238,
        y: 662,
      }
    },
    'huis-van-de-toekomst': {
      x: 835,
      y: 19,
      w: 309,
      h: 641,
      count: {
        x: 1107,
        y: 479,
      }
    }
  },
  'filterFieldMapping': { //TODO: get this configuration from backend: /jsonapi/field_config/field_config?filter[field_name][value]=field_group_size
    //'target_audience': 'field_target_audience',
    'type': 'field_type'
    //'age_group': 'field_age_group',
    //'group_size': 'field_group_size',
    //'time_frame': 'field_time_frame'
  }
}
export default Constants;
