import React from 'react'
import InfoPanel from "containers/InfoPanel/index.js"
import ApiClient from 'client/ApiClient'
import { buildJSXFromHTML} from "util/utility"


class About extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      content: null
    }
  }

  componentDidMount() {
    ApiClient.instance().fetchContent("node--page", {field_path:"/hoi"}, null, null, null, 0, function(node, included) {
      var body = (node[0].attributes.body || {}).value || ""
      var jsx = buildJSXFromHTML(body)

      this.setState({
        content: jsx
      })
    }.bind(this))
  }

  render() {
    return (
      <InfoPanel id={"hoi"} title={"HOI Foundation"} color={"yellow"}>
        {this.state.content}
      </InfoPanel>
    )
  }
}

export default About
