import React from 'react'
import ApiHelper from 'client/ApiHelper'
import { connect } from 'react-redux'
import { addToolFilter, removeToolFilter, setFilterTermNames } from 'actions'

class ToolFilters extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      filters:[],
      filterTermNames: null,
      toggled: false
    }
  }

  componentDidMount() {
    //retrieve filters from backend
    ApiHelper.instance().getFilterDefintions((filters) => {

      var filterTermNames = {}
      for(let filter of filters){
        filterTermNames = Object.assign(filter.options.reduce((obj, term) => Object.assign({[term["id"]]: term.name}, obj), filterTermNames), filterTermNames)
      }

      this.setState({ filters, filterTermNames })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.dispatch(setFilterTermNames(this.state.filterTermNames))
  }

  onToggleFiltersDisplay() {
    this.setState({
      toggled: !this.state.toggled
    })
  }

  onToggleTool(id) {
    if(this.props.filtersSelected.find((f) => f === id)) {
      this.props.dispatch(removeToolFilter(id))
    } else {
      this.props.dispatch(addToolFilter(id))
    }
  }

  onClearAll() {
    this.props.filtersSelected.forEach((id) => {
      this.props.dispatch(removeToolFilter(id))
    })
  }

  render() {

    //var clearButton = (this.props.filtersSelected.length ? <span className="button button-clear" onClick={() => {this.onClearAll()}}>clear</span> : null)

    //individual filters with options
    var filterBoxes = this.state.filters.map((filter) => {

      var className = "tool-filter " + filter.vid
      var opts = filter.options.map(opt => {
          var className = this.props.filtersSelected.indexOf(opt.id) !== -1 ? "selected" : null
          return <li className={className} key={opt.id} onClick={(evt) => {this.onToggleTool(opt.id)}}>{opt.name}</li>
      })
      return (
        <div className={className} key={filter.id}>
          <h4>{filter.name}</h4>
          <ul className="tool-filter-options">
            {opts}
          </ul>
        </div>
      )
    })

    var className = "panel tool-filters toggle"
    if(this.state.toggled) {
      className += " toggled"
    }

    //{clearButton}
    return (
      <div className={className}>
        <h3 className="toggle-button" onClick={() => {this.onToggleFiltersDisplay()}}>filter</h3>
        <div className="toggle-content">
          {filterBoxes}
        </div>
      </div>
    )
  }

}

//connect the status prop to the record for this tool in redux
const mapStateToProps = (state, ownProps) => ({
  filtersSelected: state.toolFiltersApplied
})
ToolFilters = connect(mapStateToProps)(ToolFilters)

export default ToolFilters
