import React from 'react'
import ApiClient from 'client/ApiClient'
import ApiHelper from 'client/ApiHelper'
import { buildJSXFromHTML, isID} from "util/utility"
import { Constants } from 'config/Constants.js'
import InfoPanel from "containers/InfoPanel/index.js"


class Material extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      id: props.match.params.id,
      nodeEntity: null,
      includedEntities: null,
      termEntities: null
    }
  }

  componentDidMount() {
    this.update(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({ id: nextProps.match.params.id })
      this.update(nextProps.match.params.id)
    }
  }

  update(id) {
    //get filter definitions to be able to lookup vocabulary names
    ApiHelper.instance().getFilterDefintions((definitions) => {
      this.setState({
        filterDefintions: definitions
      })
    })

    // set filter based we have a id or path
    var filter = (isID(id) ? id : { "field_path": "/tool/" + id })
    // full info on this node including relationships
    //"field_instructions", "field_material", "field_age_group_text", "field_group_size_text", "field_time_frame_text"
    var includes = [...Object.values(Constants.filterFieldMapping), "field_image", "field_download"]

    ApiClient.instance().fetchContent("node--tool", filter, null, null, includes, 0, function(node, included) {
      if(node) {
        // if we filtered by path we take the first node of the result
        if(typeof(filter) !== "string") {
          node = node[0]
        }

        //set content
        this.setState({
          nodeEntity: node,
          includedEntities: included
        })
        //lookup the terms in the hierarchy to get pathnames
        var categoryIds = ((((node || {}).relationships || {}).field_category || {}).data || []).map((cat) => {
          return cat.id
        })
        ApiHelper.instance().findTermInContentHierarchy(categoryIds, function(terms) {
          this.setState({
            termEntities: terms
          })
        }.bind(this))
      }
    }.bind(this))
  }

  // render image or whatever
  renderFile(item, meta) {
    var mime = (item.attributes || {}).filemime
    var filename, url, label
    switch(mime) {
      case "image/jpeg":
        filename = (item.attributes || {}).filename
        url = ((item.attributes || {}).uri || {}).url
        return <img key={item.id} src={ApiClient.instance().getFullURL(url)} alt={filename} />
      case "application/pdf":
        filename = (item.attributes || {}).filename
        url = ((item.attributes || {}).uri || {}).url
        label = (meta.description) ? meta.description : 'download tool'
        return <a className="button button-download" key={item.id} href={ApiClient.instance().getFullURL(url)} target="_blank" rel="noopener noreferrer">{label}</a>
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        filename = (item.attributes || {}).filename
        url = ((item.attributes || {}).uri || {}).url
        label = (meta.description) ? meta.description : 'download presentatie'
        return <a className="button button-download" key={item.id} href={ApiClient.instance().getFullURL(url)} target="_blank" rel="noopener noreferrer">{label}</a>
      default:
        console.log("entity mime not supported:", mime)
        break
    }
    return null
  }

  //get the term name from the includes for this tool
  resolveMetaData(relationshipName) {
    //this.state.nodeEntity, fieldName, this.state.includedEntities
    if(this.state.nodeEntity && this.state.includedEntities && this.state.filterDefintions) {
      var idRelated = (((this.state.nodeEntity["relationships"] || {})[relationshipName] || {})["data"] || {})["id"]
      if(idRelated) {
        //find term
        var term = (this.state.includedEntities || []).find((el) => {
          return (el || {}).id === idRelated
        })
        //lookup filter/vocabulary name
        var vocabularyId = ((((term || {}).relationships || {}).vid || {}).data || {}).id
        var filter = (this.state.filterDefintions || []).find((def) => {
          return def.id === vocabularyId
        })
        //return filter/value
        var name = (filter || {}).name
        var value = ((term || {}).attributes || {}).name
        return {name: name, value: value}
      }
    }
    return null
  }

  render() {
    //show loading till we have fetched all
    var content

    //build content view when we have all data
    if(this.state.nodeEntity) {
      const attributes = this.state.nodeEntity.attributes

      var title = attributes.title || ""
      var color = "yellow"

      //make tool content
      var body = (attributes.body || {}).value || ""
      body = buildJSXFromHTML(body)

      var materials
      if (attributes.field_material) {
        materials = (
          <tr>
            <th>materials:</th>
            <td>
              <ul>
                { attributes.field_material.map((material, i) => { return <li key={i}>{material}</li> }) }
              </ul>
            </td>
          </tr>
        )
      }

      var instructions
      if(attributes.field_instructions) {
        instructions = (
          <div>
            <h3>instructions:</h3>
            {buildJSXFromHTML(attributes.field_instructions.value)}
          </div>
        )
      }

      // includes
      var filesMeta = ((this.state.nodeEntity.relationships || {}).field_download || {}).data || []

      var files = (this.state.includedEntities || [])
        .filter((item) => item.type === 'file--file')

      // var images = (this.state.includedEntities || [])
      //   .filter((item) => item.type === 'media--image')
      //   .map((item) => { return this.renderFile(item) })

      var images = (files || [])
        .filter((item) => item.attributes.filemime === "image/jpeg")
        .map((item) => { return this.renderFile(item) })

      var downloads = (files || [])
        .filter((item) => item.attributes.filemime === "application/pdf" || item.attributes.filemime === "application/vnd.openxmlformats-officedocument.presentationml.presentation")
        .map((item) => {
          var meta = filesMeta.filter((itemMeta) => itemMeta.id === item.id)[0].meta
          return this.renderFile(item, meta)
        })

      var links = (attributes.field_link || [])
        .map((item, key) => {
          return <a className="button" key={key} href={item.uri} target="_blank" rel="noopener noreferrer">{item.title}</a>
        })

      const fields = [
        { name: "field_time_frame_text", title: "time frame" },
        { name: "field_group_size_text", title: "group size" },
        { name: "field_age_group_text", title: "age group" }
      ].map((field) => {
        if(attributes[field.name]) {
          return (
            <tr key={field.name}>
              <th>{ field.title }:</th><td>{ attributes[field.name] }</td>
            </tr>
          )
        } else {
          return false
        }
      })

      //metadata fields
      // var metaDataFields = Object.values(Constants.filterFieldMapping).map((fieldName) => {
      //   var metaData = this.resolveMetaData(fieldName)
      //   var field = (
      //     <div key={fieldName} className={"tool-meta " + fieldName.slice(6)}>
      //       <span className="tool-meta-name">{(metaData || {}).name}</span>
      //       <span className="tool-meta-value">{(metaData || {}).value}</span>
      //     </div>
      //   )
      //   return metaData ? field : null
      // }).filter(metaDataProperty => metaDataProperty)

      //{(metaDataFields.length > 0 ? <div className="tool-metas">{metaDataFields}</div> : null )}
      content = (
        <div className="tool">
          {body}
          <table className="properties"><tbody>
            {fields}
            {materials}
          </tbody></table>
          {instructions}
          {links}
          {downloads}
        </div>
      )
    }

    return (
      <InfoPanel id="" images={images} title={title} color={color}>
        {content}
      </InfoPanel>
    )
  }
}

export default Material
