import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setTitle, setInfoPanel, setId } from 'actions'
import { Constants } from 'config/Constants.js'

class InfoPanel extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      infoPanel: true,
      color: props.color,
      id: props.id,
      icon: props.icon,
      images: props.images || [],
      title: props.title,
      children: props.children
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.title !== state.title) {
      return {
        id: props.id,
        icon: props.icon,
        color: props.color,
        images: props.images,
        title: props.title,
        infoPanel: true
      }
    }
    if (props.children !== state.children) {
      return {
        children: props.children
      }
    }
    return null
  }

  componentDidUpdate() {
    if(this.state.title) this.props.dispatch(setTitle(this.state.title))
    this.props.dispatch(setInfoPanel(this.state.infoPanel))
    this.props.dispatch(setId(this.state.id))
  }

  toggleInfoPanel() {
    this.setState({
      infoPanel: !this.state.infoPanel
    })
  }

  handleViewportClick() {
    // only if smaller than info panel breakpoint ($info-panel-breakpoint in scss)
    if(window.innerWidth < Constants.infoPanel.breakpoint) {
      this.props.history.push('/')
    }
  }

  handleContentClick(e) {
    e.stopPropagation()
  }

  closeInfoPanel() {
    this.props.dispatch(setTitle(''))
    this.props.dispatch(setInfoPanel(false))
    this.props.history.push('/')
  }


  render() {
    var className = [this.state.id, this.state.color]
    if (!this.state.infoPanel) {
      className.push('collapsed')
    }

    const icon = this.state.icon ? <img className="icon" src={this.state.icon} alt="" /> : null
    //const subtitle = this.state.subtitle ? <h2 className="subtitle">{this.state.subtitle}</h2> : null

    return (
      <div id="info-panel" className={className.join(" ")} onClick={ this.handleViewportClick.bind(this) }>
        <div id="info-panel-inner">

          <div id="info-panel-buttons" ref="infoPanelButtons"><div>
            <span className="button-info-panel button-back" onClick={ this.props.history.goBack}><span className="text">terug</span></span>
            <span className="button-info-panel button-close" onClick={ this.closeInfoPanel.bind(this) }></span>
            <span className="button-info-panel button-collapse" onClick={ this.toggleInfoPanel.bind(this)}></span>
          </div></div>

          <div id="info-panel-viewport" ref="infoPanelViewport" >
            <div id="info-panel-content" onClick={ this.handleContentClick.bind(this) }>
              <div id="info-panel-header">
                {(this.state.images && this.state.images.length > 0 ? this.state.images[0] : null )}
                { this.state.title ?
                <h1 className="info-panel-title">
                  {icon}
                  <span>{this.state.title}</span>
                </h1> : null
                }
              </div>
              <div id="info-panel-body" className="content">{this.state.children}</div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  //infoPanel: state.infoPanel
})
InfoPanel = connect(mapStateToProps)(InfoPanel)

export default withRouter(InfoPanel)