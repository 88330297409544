import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Constants } from 'config/Constants.js'
import DocumentMeta from 'react-document-meta';

//import Header from "containers/Header"
import Page from "containers/Page.js"
import Map from "containers/Map"

import 'styles/styles.css'


const { detect } = require('detect-browser')

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      title : '',
      width: 1200,
      height: 800
    }
    //this.handleKeyUp = this.handleKeyUp.bind(this)
    //window.addEventListener("keyup", this.handleKeyUp.bind(this))
  }

  componentDidMount() {
    const browser = detect()
    // handle the case where we don't detect the browser
    if (browser && browser.name === "ie") {
      alert("Deze website heeft een moderne browser nodig. Bijvoorbeeld een recente versie van Firefox, Edge, Chrome, Opera of Safari.")
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.title !== state.title) {
      return {
        title: props.title,
      }
    }
    return null
  }

  // handleKeyUp(e) {
  //   if(["1", "2", "3", "4", "5"].indexOf(e.key) > -1) {
  //     window.svgmap.animateZoomPan(1.75, [Constants.categories[e.key].x + 75,Constants.categories[e.key].y + 15])
  //   }

  //   if(e.key === "w") {
  //     window.svgmap.animateZoomPan(1.4, [645,577.5])
  //   }

  //   if(e.key === "z") {
  //     window.svgmap.animateZoomPan(1, [500,480])
  //   }

  //   if(e.key === "m") {
  //     window.svgmap.animateZoomPan(1, [500,480])
  //   }
  // }


  render() {
    const title = (this.state.title ? this.state.title + " « " : "")
    const meta = {
      title: title + Constants.title
    }

    return (
      <DocumentMeta {...meta}>
        <Router>
          <div id="container-app">
            {/* <Header /> */}
            <Switch>
              <Route path="/" component={Map} />
              <Route path="*" component={props => <Page remotePath="/404"/>} status={404} />
            </Switch>
          </div>
        </Router>
      </DocumentMeta>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  title: state.title
})
App = connect(mapStateToProps)(App)

export default App
