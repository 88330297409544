
const Config = {
  endPoint : {
    host: "happydyslectisch.nl",
    port: "",
    path: "drupal",
    protocol: "https"
  }
}

export default Config;
